import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appCountryContactDetails]'
})
export class CountryContactDetailsDirective {
elem: ElementRef;
renderer2: Renderer2;
textVal: string;
brTag: any;
lts: boolean;
igac: boolean;
tsac: boolean;
romaniaVerifier: boolean;

isaGrmsEmail: string;
techSupportEmail: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.elem = el;
    this.renderer2 = renderer;
    this.brTag = renderer.createElement('br');
   }

  ngAfterViewInit(){

    this.textVal = this.elem.nativeElement.innerText;
    
    if(this.checkTextValue(this.textVal) != true){
      this.renderer2.appendChild(this.elem.nativeElement, this.brTag);
    }else{
      // if(this.lts){
      //   this.lts = false;
      // }else if(this.igac){
      if(this.igac){
        this.igac = false;
        this.renderer2.appendChild(this.elem.nativeElement, this.brTag);
      }else if(this.tsac && this.romaniaVerifier == true){
        this.tsac = false;
        this.romaniaVerifier = false;
        this.renderer2.appendChild(this.elem.nativeElement, this.brTag);
 
      }
    }
    
  }

  checkTextValue(text){
    if(text.includes(this.isaGrmsEmail)){
      this.romaniaVerifier = true;
    this.igac = true;
    return true;
    }else if(text.includes(this.techSupportEmail)){
    this.romaniaVerifier = true
    this.tsac = true;
    return true;
    }
    else{
    return text.includes("Toll Free") || text.includes("International") || text.includes("Extension") || text.includes(".com") || text.includes("Domestic");
    }
  }

  getEmailAdds() {
    try {
      let emails = JSON.parse(sessionStorage.getItem('Cache Email Data'));
      
      let techSupportEmailObj = emails.find(email => email.Value.includes("Leadership.Equity"));
      if (techSupportEmailObj) {
        this.techSupportEmail = techSupportEmailObj.Value;
      }

      let isaGrmsEmailObj = emails.find(email => email.Value.includes("ISA.GRMS"));
      if (isaGrmsEmailObj) {
        this.isaGrmsEmail = isaGrmsEmailObj.Value;
      }
    } catch (error) {
      console.log('ERROR: ', error);
    }
  }

}
