import { Injectable } from "@angular/core";
declare var require: any;

@Injectable()
export class Globals {
   env: any = require('../../env.json');
   path:any = require('../../path.json');

   highlightsKey:string  = "Highlights";
   highlightsPage:string = 'Highlights Page';
   profileInfoKey:string = "ProfileInfo";
   isHighlightsCached:string = "highlightsCached";
   isUserInfoCached: string = "User Info Cached";
   isUserLoginUpdated: string = "User Login Update";
   marketQuoteDatafromExtAPI: any;
   marketQuoteDatafromDynamoDB: any;
   showDefaultHeaderFooter: any = 0;
   programDetailsKey: string = "Holding Program Details";
   grantTabInd: string = "Grant Tab Indicator";
   localCurrencyCd: string = "Local Currency Code";
   selectedCurrencyCd: string = "Selected Currency Code";
   ESPPenrollmentDatakey: any;
   FrenchQualified = 0;
   


   isFormer: string = "isFormerChecker";
   AdminCred: string = "Admin info";

   //Change Currency After Dashboard Changes
   currencyNewValue : string = '5000'; 
   currencyNewSign : string = 'AOA'

   //ESO token/credentials
   tokenKey: any = "";
   esoEnterpriseId: string = "";
   accessToken: any = "";
   sessionOptNum: string = "OptNum from session";

   //KeyExec
   keyExecVest: string = "Target";
   keyExecData: any;
   keyExecTargetVest: any;
   keyExecTrendVest: any;
   keyExecMaxVest: any;

   //current URLs
   currentWebUrl = this.env.URL; 
   currentAPIUrl = this.env.API;
   stageName = this.env.StageName;
   myResourcesURL = this.env.myResourcesUrl;
   notifFramework = this.env.notifFramework;    

   //path- IJM
   getUserInfoAPIPath = this.path.getUserInforAPI;
   updateUserLoginPath = this.path.updateUserLogin;
   updateUserPrefCodePath = this.path.updateUserPrefCode;
   getRSUVestAccordionPath = "/" + this.stageName + "/" + this.path.getRSUVestInfo;
      
   //Web URLS
   // urlWeb" + this.stageName + "   = "https://myholdings-fortify.cio" + this.stageName + ".accenture.com";
   // urlWebTest  = "https://myholdings-fortify.ciotest.accenture.com";
   // urlWebStage = "https://myholdings-fortify.ciostage.accenture.com";
   // urlWebProd  = "https://myholdings-fortify.accenture.com";
   

   //API URLs
   // urlAPI" + this.stageName + "   = "https://myholdings-fortify-api.cio" + this.stageName + ".accenture.com";
   // urlAPITest  = "https://myholdings-fortify-api.ciotest.accenture.com";
   // urlAPIStage = "https://myholdings-fortify-api.ciostage.accenture.com";
   // urlAPIProd  = "https://myholdings-fortify-api.accenture.com";
   
   //Static param values
   staticFormerEmplNotice = "Former Employee Notice";
   staticLegalNotice = "Legal Notice";
   staticHighlightsSortType = "Highlights Sort type";
   staticPersonalSysImpl = "Personnel System Implementation";
   staticBrokersNotice = "Contact Brokers Notice";
   staticSalesHistoryPeriod = "Sales history dropdown";
   staticEmailAdds = "Email Addresses";

   /* API URLS PER FUNCTIONALITY */
   //Static Content
   staticContentAPI:string = "/" + this.stageName + "/" + this.path.staticContentAPI;
   exchangeRateListAPI:string = "/" + this.stageName + "/" + this.path.exchangeRateListAPI;

   //User Service
   //getUserInfoAPI = "/" + this.stageName + "/users/getUserProfile?OptNum=";
   //updateUserLogin = "/" + this.stageName + "/users/putLastLogin";
   //updateUserPrefCode = "/" + this.stageName + "/exchangerate/updateCurrency?currencyPrefCode=[param1]&HideAwardsInd=[param2]&date=[param3]&OptNum=[param4]";
   //User Service
   getUserInfoAPI = "/" + this.stageName + "/" + this.getUserInfoAPIPath;
   updateUserLogin = "/" + this.stageName + "/" + this.updateUserLoginPath;
   updateUserPrefCode = "/" + this.stageName + "/" + this.updateUserPrefCodePath;
   
   //Highlights Service
   getUserHighlightsAPI = "/" + this.stageName + "/" + this.path.getUserHighlightsAPI;
   getMarketQuoteAPI = "/" + this.stageName + "/" + this.path.getMarketQuoteAPI; //"/" + this.stageName + "/updatemarketquote/getMarketQuoteExternal"; //"https://api.iextrading.com/1.0/stock/acn/batch?types=quote";  //https://cloud.iexapis.com/stable/stock/acn/batch?types=quote&token=sk_22948ddceb104432892611651d9a01e2
   // getMarketQuoteGraphAPI = "https://cloud.iexapis.com/stable/stock/acn/batch?types=chart&range=";
   // getMarketQuoteTokenAPI = "&token=sk_22948ddceb104432892611651d9a01e2";
   getMarketQuotefromDB = "/" + this.stageName + "/" + this.path.getMarketQuotefromDB; ///getmarketquote

   //Holding Summary Service
    getPurchaseProgramAPI = "/" + this.stageName + "/" + this.path.getPurchaseProgramAPI;
   //getPurchaseProgramAPI = "/" + this.stageName + "/holdingprogramsummary/getProgSummPurchase"; //Sprint 2 changes on API URLs
   getExchangeRateAPI = "/" + this.stageName + "/" + this.path.getExchangeRateAPI;
   getFounderSharesAPI = "/" + this.stageName + "/" + this.path.getFounderSharesAPI;
   //getFounderSharesAPI = "/" + this.stageName + "/holdingprogramsummary/getProgSummFdr"; //Sprint 2 changes on API URLs
   getOptionAPI = "/" + this.stageName + "/" + this.path.getOptionAPI;
   //getOptionAPI = "/" + this.stageName + "/holdingprogramsummary/getProgSummOpt"; //Sprint 2 changes on API URLs
   getRSUAPI = "/" + this.stageName + "/" + this.path.getRSUAPI;
   //getRSUAPI = "/" + this.stageName + "/holdingprogramsummary/getProgSumRSU"; //Sprint 2 changes on API URLs

   //Key Exec Summary
   getKeyExecSummaryAPI = "/" + this.stageName + "/" + this.path.getKeyExecSummaryAPI;
   getkeyExecToggleAPI = "/" + this.stageName + "/" + this.path.getkeyExecToggleAPI;
   //PEAP

   getperformanceGrantAPI = "/" + this.stageName + "/" + this.path.getperformanceGrantAPI;
   gettotalTargetCashAPI  = "";

   //ProfilePictureAPI
   getUserProfilePicAPIProd = "https://collab-ts.accenture.com/m/oav2/api/ProfilePicture/eid/[param]";
   getUserProfilePicPerf = "https://collab-ts.cioperf.accenture.com/m/oav2/api/ProfilePicture/eid/[param]";
   getProfPicAPI = "/" + this.stageName + "/" + this.path.getProfPicAPI;

   //Card indicator
   getCardIndicatoraAPI = "/" + this.stageName + "/" + this.path.getCardIndicatoraAPI;
 
   //People Page
   peopleUrl = "https://people.accenture.com/People/user/[param]"

   //Holding Program Details Service 
   getAwardDetailsESPPAPI = "/" + this.stageName + "/" + this.path.getAwardDetailsESPPAPI;
   getSalesHistESPPAPI = "/" + this.stageName + "/" + this.path.getSalesHistESPPAPI; 
   
   getAwardDetailsVEIPAPI = "/" + this.stageName + "/" + this.path.getAwardDetailsVEIPAPI;
   getSalesHistVEIPAPI = "/" + this.stageName + "/" + this.path.getSalesHistVEIPAPI;
   getCarryOverVEIPAPI = "/" + this.stageName + "/" + this.path.getCarryOverVEIPAPI;
   getveipMatchingGrantAPI = "/" + this.stageName + "/" + this.path.getveipMatchingGrantAPI;    
   
   getAwardDetailsOptionsAPI = "/" + this.stageName + "/" + this.path.getAwardDetailsOptionsAPI;
   getOptionsSalesHistory = "/" + this.stageName + "/" + this.path.getOptionsSalesHistory;
   getOtionsAgreement = "/" + this.stageName + "/" + this.path.getOtionsAgreement;
   acceptGrantAcceptBtnOptionsAPI = "/" + this.stageName + "/" + this.path.acceptGrantAcceptBtnOptionsAPI;

   getAwardDetailsFounderAPI = "/" + this.stageName + "/" + this.path.getAwardDetailsFounderAPI;
   getAwardSalesHistFounderAPI = "/" + this.stageName + "/" + this.path.getAwardSalesHistFounderAPI;
   getTransfereeFdrAPI = "/" + this.stageName + "/" + this.path.getTransfereeFdrAPI;

   getRSUDetailsAPI = "/" + this.stageName + "/" + this.path.getRSUDetailsAPI;
   getPSUDetailsAPI = "/" + this.stageName + "/" + this.path.getPSUDetailsAPI;
   getPSUDetailsByOptNumAPI = "/" + this.stageName + "/" + this.path.getPSUDetailsByOptNumAPI;

   getRSUDetailsRelInfoAPI = "/" + this.stageName + "/" + this.path.getRSUDetailsRelInfoAPI;
   getRSUDetailsDivInfoAPI = "/" + this.stageName + "/" + this.path.getRSUDetailsDivInfoAPI;
   getRSUDetailsVestInfoAPI = "/" + this.stageName + "/" + this.path.getRSUDetailsVestInfoAPI;
   getRSUSalesHistoryAPI = "/" + this.stageName + "/" + this.path.getRSUSalesHistoryAPI;
   getRSUAgreementAPI = "/" + this.stageName + "/" + this.path.getRSUAgreementAPI;
   acceptGrantAcceptBtnRSUAPI = "/" + this.stageName + "/" + this.path.acceptGrantAcceptBtnRSUAPI;

   getRSUIPODetailsAPI = "/" + this.stageName + "/" + this.path.getRSUIPODetailsAPI;
   getRSUIPODetailsRelInfoAPI = "/" + this.stageName + "/" + this.path.getRSUIPODetailsRelInfoAPI;
   getRSUIPODetailsVestInfoAPI = "/" + this.stageName + "/" + this.path.getRSUIPODetailsVestInfoAPI;
   getRSUIPODetailsGrantInfoAPI = "/" + this.stageName + "/" + this.path.getRSUIPODetailsGrantInfoAPI;
   getRSUIPOSalesHistoryAPI = "/" + this.stageName + "/" + this.path.getRSUIPOSalesHistoryAPI;

   getAdminSearchAPI = "/" + this.stageName + "/" + this.path.getAdminSearchAPI;

   getEorAPI = "/" + this.stageName + "/" + this.path.getEorAPI;
   
   getNotificationAPI = "/" + this.stageName + "/" + this.path.getNotificationAPI;

   getESPPEnrollPercentAPI = "/" + this.stageName + "/" + this.path.getESPPEnrollPercentAPI;
   getESPPEnrollDetailsAPI = "/" + this.stageName + "/" + this.path.getESPPEnrollDetailsAPI;
   getValuefromCodeDetail = "/" + this.stageName + "/" + this.path.getValuefromCodeDetail;
   // postEsppTransTable = "/" + this.stageName + "/enrollwithdrawespp/insertEnrollWithdrawESPP?SSN=[param1]&WithHoldingPcnt=[param2]&TransDt=[param3]&Userfield=[param4]&OfferNum=[param5]&OptNum=[param6]&BPurchase=[param7]&Updatedby=[param8]&Transtype=[param9]";
   postEsppTransTable = "/" + this.stageName + "/" + this.path.postEsppTransTable;
   // GDPR Flag
   gdprFlag = "/" + this.stageName + "/" + this.path.gdprFlag;
   hideOutstandingShares = "/" + this.stageName + "/" + this.path.hideOutstandingShares;
   getVEIPOfferingDetailsAPI = "/" + this.stageName + "/" + this.path.getVEIPOfferingDetailsAPI;
   getVEIPDefaultEligibilityAPI = "/" + this.stageName + "/" + this.path.getVEIPDefaultEligibilityAPI;
   getVEIPTransactionDetailsAPI = "/" + this.stageName + "/" +  this.path.getVEIPTransactionDetailsAPI;
   postVEIPTransactionDetails = "/" + this.stageName + "/" + this.path.postVEIPTransactionDetails;
   
   getUserDetailsByOptNumAPI = "/" + this.stageName + "/" + this.path.getUserDetailsByOptNumAPI;

   getRSUDivSumAPI = "/" + this.stageName + "/" + this.path.getRSUDivSumAPI;

   //Send Email API
   sendMailAPI = "/" + this.stageName + "/" + this.path.sendMailAPI;

   //Action Item API
   getESPPActionitemAPI = "/" + this.stageName + "/" + this.path.getESPPActionitemAPI;
   getVEIPActionItemAPI = "/" + this.stageName + "/" + this.path.getVEIPActionItemAPI;
   getGrantAlertAPI = "/" + this.stageName + "/" + this.path.getGrantAlertAPI;
   
   //Bell Grant Alert
   getAlertfromBellAPI = "/" + this.stageName + "/" + this.path.getAlertfromBellAPI;

   //User Reports API
   getUserReports = "/" + this.stageName + "/" + this.path.getUserReports;

   //Reports API
   getSharePriceHistoryAPI = "/" + this.stageName + "/" + this.path.getSharePriceHistoryAPI;

   //reports
   getEnrollmentESPPHistoryAPI = "/" + this.stageName + "/" + this.path.getEnrollmentESPPHistoryAPI;
   getEnrollmentVEIPHistoryAPI = "/" + this.stageName + "/" + this.path.getEnrollmentVEIPHistoryAPI;
   getEnrollmentAllHistoryAPI =  "/" + this.stageName + "/" + this.path.getEnrollmentAllHistoryAPI;

   getHasESPPAPI = "/" + this.stageName + "/" + this.path.getHasESPPAPI;
   getHasVEIPAPI = "/" + this.stageName + "/" + this.path.getHasVEIPAPI;
   getHasGrantAPI = "/" + this.stageName + "/" + this.path.getHasGrantAPI;
   getHasRSUAPI = "/" + this.stageName + "/" + this.path.getHasRSUAPI;
   getHasOptionsAPI = "/" + this.stageName + "/" + this.path.getHasOptionsAPI;
   getUserReportsAPI = "/" + this.stageName + "/" + this.path.getUserReportsAPI;
   getEnrollDropDownAPI = "/" + this.stageName + "/" + this.path.getEnrollDropDownAPI;

   //Purchase Report
   getPurchaseProgramESPPAPI = "/" + this.stageName + "/" + this.path.getPurchaseProgramESPPAPI; 
   getPurchaseProgramVEIPAPI = "/" + this.stageName + "/" + this.path.getPurchaseProgramVEIPAPI;
   getPurchaseProgramDropDownAPI = "/" + this.stageName + "/" + this.path.getPurchaseProgramDropDownAPI;

   //RSU Activity Report
   getRSUActivityAward = "/" + this.stageName + "/" + this.path.getRSUActivityAward;
   getRSUActivityDropDownAPI = "/" + this.stageName + "/" + this.path.getRSUActivityDropDownAPI;

   //Individual Transfer Limit Report
   getITLReportAPI = "/" + this.stageName + "/" + this.path.getITLReportAPI;
   
   //Sales History Report
   getSalesHistoryReportsAPI = "/" + this.stageName + "/" + this.path.getSalesHistoryReportsAPI;
   getSalesHistoryProgramDropDownAPI = "/" + this.stageName + "/" + this.path.getSalesHistoryProgramDropDownAPI;
   getSalesHistoryGrantsDropDownAPI = "/" + this.stageName + "/" + this.path.getSalesHistoryGrantsDropDownAPI;
   

   //Contacts Page
   getBrokerAccountsAPI = "/" + this.stageName + "/" + this.path.getBrokerAccountsAPI;
   getGlobalIDhistAPI = "/" + this.stageName + "/" + this.path.getGlobalIDhistAPI;

   //Exchange Rate
   getExchangeRateReportsAPI = "/" + this.stageName + "/" + this.path.getExchangeRateReportsAPI;
   getExchangeRtCurrencyList  = "/" + this.stageName + "/" + this.path.getExchangeRtCurrencyList;
   
   // ESPP Tax Election
   getESPPTaxElectionAPI = "/" + this.stageName + "/" + this.path.getESPPTaxElectionAPI;
   insertTaxElect = "/" + this.stageName + "/" + this.path.insertTaxElect;

   // country specific tax info
   getCountryList = "/" + this.stageName + "/" + this.path.getCountryList;
   getCountryFile = "/" + this.stageName + "/" + this.path.getCountryFile;
   
   // Error Handling Api
   logErrorAPI = "/" + this.stageName + "/" + this.path.logErrorAPI;

   //Generate Reports API
   generateExchangeRateHistoryAPI = "/" + this.stageName + "/" + this.path.generateExchangeRateHistoryAPI;
   generateSharePriceHistoryAPI = "/" + this.stageName + "/" + this.path.generateSharePriceHistoryAPI;
   generateRSUActivityAPI = "/" + this.stageName + "/" + this.path.generateRSUActivityAPI;
   generateESPPEnrollmentHistoryAPI = "/" + this.stageName + "/" + this.path.generateESPPEnrollmentHistoryAPI;
   generateVEIPEnrollmentHistoryAPI = "/" + this.stageName + "/" + this.path.generateVEIPEnrollmentHistoryAPI;
   generateALLEnrollmentHistoryAPI = "/" + this.stageName + "/" + this.path.generateALLEnrollmentHistoryAPI;
   generateESPPPurchaseProgramAPI = "/" + this.stageName + "/" + this.path.generateESPPPurchaseProgramAPI;
   generateVEIPPurchaseProgramAPI = "/" + this.stageName + "/" + this.path.generateVEIPPurchaseProgramAPI;
   generateSalesHistoryAPI = "/" + this.stageName + "/" + this.path.generateSalesHistoryAPI;
   generateIndividualTransferLimitAPI = "/" + this.stageName + "/" + this.path.generateIndividualTransferLimitAPI;

   // Dividend
   getDividendsChart = "/" + this.stageName + "/" + this.path.getDividendsChart;

   //Resources tab for donwload file
   manageFile = "/" + this.stageName + "/" + this.path.manageFile;
   managePageData = "/" + this.stageName + "/" + this.path.managePageData;

   // Equity Admin
   eaApiUrl: string = this.env.EquityAdminAPIURL;
   manageUserAccessApi: string = this.eaApiUrl + "/"+ this.stageName +"/ejet/manageuseraccess";
   manageWeeklySummaryApi: string = this.eaApiUrl + "/"+ this.stageName +"/ejet/manageweeklysummary"; 
   manageImportInvoiceApi: string = this.eaApiUrl + "/"+ this.stageName +"/ejet/manageimportinvoice";
   manageInvoiceDescApi: string = this.eaApiUrl + "/"+ this.stageName +"/ejet/manageinvoicedescription";
   manageCompCodeApi: string = this.eaApiUrl + "/"+ this.stageName +"/ejet/manageholdingcocd";
   manageAccNumberApi: string = this.eaApiUrl + "/"+ this.stageName +"/ejet/manageaccountnumber";
   manageBillToApi: string = this.eaApiUrl + "/"+ this.stageName +"/ejet/managebillto";
   manageCheckCtr: string = this.eaApiUrl + "/"+ this.stageName +"/ejet/managecheckctr";

   invoiceDescDisplayedColumns: string[] = ["EQUITY_TYPE_DESC", "JOURNAL_ENTRY_TYPE_CD", "JOURNAL_GROUP_ID", "JOURNAL_ROW_ID", "PROFIT_CENTER_ID", "Description", "EFFECTIVE_POSTING_DATE"];

   compCodeDisplayedColumns: string[] = ["HOLDING_COMPANY_TYPE_DESC", "EQUITY_TYPE_DESC", "ISSUE_TYPE_DESC", "CHC_CO_CD", "EFFECTIVE_POSTING_DATE"];

   accountNumDisplayedColumns: string[] = ["ACCOUNT_NUMBER", "ACCOUNT_NAME_DESC", "EQUITY_TYPE_DESC", "EFFECTIVE_POSTING_DATE"];
    
   billToDisplayedColumns: string[] = ['COUNTRY', 'INVOICE_FILE_CO_CD', 'BILL_TO_CO_CD', 'EQUITY_TYPE', 'JOURNAL_ENTRY_TYPE', 'BILL', 'CANCEL_WITHHOLDING', 'PARTNER_REC', 'EFFECTIVE_POSTING_DATE'];

   //SEM
   api_URL_accessTool: string = this.eaApiUrl + "/"+ this.stageName +"/sem/accesstool";
   api_URL_pds: string = this.eaApiUrl + "/"+ this.stageName +"/sem/pds";
   api_URL_pdsDocument: string = this.eaApiUrl + "/"+ this.stageName +"/sem/pdsdocument";;
   api_URL_pdsUnit: string = this.eaApiUrl + "/"+ this.stageName +"/sem/pdsunit";
   api_URL_reportList: string = this.eaApiUrl + "/"+ this.stageName +"/sem/pdsrpts";

   semSeeAccess: string[] = ["semCardSemSeet","SEMRt","semCardSemMr"];
   semAccess: string[] = ["semCardAm","semCardFa","semCardPdsa"];

   documentID: string = "documentId";

   route_personalData: string = "equityadmin/sem/pdsa/partnerselected/personaldata";
   route_education: string = "equityadmin/sem/pdsa/partnerselected/education";
   route_notes: string = "equityadmin/sem/pdsa/partnerselected/notes";
   route_businessInformation: string = "equityadmin/sem/pdsa/partnerselected/businessinformation";
   route_legal: string = "equityadmin/sem/pdsa/partnerselected/legaldocuments";
   route_unitAward: string = "equityadmin/sem/pdsa/partnerselected/unitaward";
   route_memberFirm: string = "equityadmin/sem/pdsa/partnerselected/memberfirm";
   route_unitHistory: string = "equityadmin/sem/pdsa/partnerselected/unithistory";
   route_projectedChanges: string = "equityadmin/sem/pdsa/partnerselected/projectedchanges";
   route_ri: string = "equityadmin/sem/pdsa/partnerselected/ri";
   route_changeLog: string = "equityadmin/sem/pdsa/partnerselected/changelog";
   route_documentError: string = "equityadmin/sem/pdsa/partnerselected/documenterror";

   searchLoading: boolean = true;
   searchInput: string;
   //end SEM

   notAuthorized: string = "You are not authorized to access this page.";
   noAccess: string = "You are not authorized to access this application.";
   weeklySummaryDisplayedColumns: string[] = ["SOURCE_NAME", "DESCRIPTION", "POSTING_DATE", "TRANSLATION_DATE", "Commit", "Rollback"];
   dataApprovalDisplayedColumns: string[] = ["JOURNAL_GROUP_DESC", "HEADER_CO_CD", "DR_CR", "ACCOUNT_NUMBER", "ROW_CO_CD", "AMOUNT", "Action"]
   reportsPageSizeOptions: number[] = [10, 25, 50, 100];
   fileExists: string = "FileName Already Exist In The DataBase!";
   excelFileOnly: string = "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

   //AwardYear
   yearHasAwardStart = 2021;
   yearCondition = 2022;
}